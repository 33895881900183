@import "@material/react-tab-bar/index.scss";
@import "@material/react-tab-scroller/index.scss";
@import "@material/react-tab/index.scss";
@import "@material/react-tab-indicator/index.scss";
@import "@material/react-linear-progress/index.scss";
@import "@material/react-dialog/index.scss";
@import "@material/react-button/index.scss";
@import "@material/react-text-field/index.scss";
@import "@material/react-material-icon/index.scss";
@import "@material/react-radio/index.scss";
@import "@material/react-list/index.scss";
@import "@material/react-chips/index.scss";
@import "@material/react-checkbox/index.scss";
@import "@material/react-switch/index.scss";
@import "@material/react-menu-surface/index.scss";
@import "@material/react-menu/index.scss";
@import "@material/react-select/index.scss";
@import "@material/react-snackbar/index.scss";
@import "@material/react-card/index.scss";


@import "./colors.scss";
@import "./text.scss";

*:not(i) {
  font-family: $default-font !important;
}

h3 {
  font-weight: 50;
}

.l-app {
  width: 100%;
  height: 100%;
  font-family: $default-font;
  --mdc-theme-primary: $default-blue;
}

.l-admin-page {
  height: 100%;
  width: 100%;
  display: flex;
}

.m-submit-button {
  margin-top: 25px;
  float: right;
}

.m-centralize {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.mdc-tab {
  @include mdc-tab-active-text-label-color($default-blue);
  @include mdc-tab-states-color($default-blue);
  @include mdc-tab-ink-color($default-blue);
}

.mdc-tab-indicator{
  @include mdc-tab-indicator-underline-color($default-blue);
}

.mdc-linear-progress {
  @include mdc-linear-progress-bar-color($loading-highlight);
}

.mdc-button {
  @include mdc-button-outline-color($default-blue);
  @include mdc-button-ink-color($default-blue);
  @include mdc-button-container-fill-color(transparent);
  @include mdc-button-outline-width(1px);
  margin: 5px;
}

.mdc-text-field {
  @include mdc-text-field-fill-color(transparent);
  @include mdc-text-field-caret-color($default-blue);
  @include mdc-text-field-line-ripple-color($default-blue);
  width: 100%;
}


.mdc-text-field--focused {
  @include mdc-text-field-label-color($default-blue);
  @include mdc-text-field-fill-color(transparent);
}


.mdc-dialog__container {
  max-height: 600px;
}

.mdc-list-item--selected {
  @include mdc-list-item-primary-text-ink-color($default-blue);
  @include mdc-list-item-secondary-text-ink-color($default-blue);
  background-color: lighten($color: $default-blue, $amount: 80);
  border: solid 1px gray;
}

.mdc-select {
  @include mdc-select-label-color($default-blue);
  @include mdc-select-focused-label-color(gray);
  @include mdc-select-icon-color($default-blue);
}

.mdc-select--focused .mdc-select__dropdown-icon {
  background: none;
}

.mdc-snackbar .mdc-snackbar__action {
  color: white;
}

.s-selected-button {
  @include mdc-button-outline-width(2px);
}

.m-text-small-input {
  width: 240px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.mdc-select__menu {
  width: 150px;
  max-height: 500px !important;
}
