@import "../../colors.scss";
@import "../../text.scss";
@import "@material/react-linear-progress/index.scss";

$max-content-width: 600px;

header {
  position: relative;
  width: 100%;
  background-color: $default-blue;
  padding-top: 0;
  padding-bottom: 0;
}

.l-main-logo-img {
  height: 8em;
  width: auto;
  filter: grayscale(100%) brightness(280%);
}

.l-main-logo-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
}

.l-footer-logo-img {
  display: none;
  height: 8em;
}

.l-tab-bar {
  max-width: $max-content-width;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
}

.l-main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 90%;
  padding-bottom: 9em;
}

.l-header {
  width: 90%;
  max-width: $max-content-width;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}

.l-content {
  width: 90%;
  max-width: $max-content-width;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;  
  margin-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
}

.l-loading-bar {
  min-height: 4px;
  position: fixed;
  top: 0;

  .mdc-linear-progress__buffer {
    background-color: transparent;
  }

  .mdc-linear-progress__buffering-dots {
    display: none;
  }
}

.l-main-footer {
  position: absolute;
  height: fit-content;
  padding-top: 1em;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  background-color: #e0e0e0;

  p {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.l-main-footer-grid-container {
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

@media only screen and (min-width: 650px) {
  .m-header-text-img {
    max-width: 520px;
  }

  .l-faca-aqui-img {
    padding-top: 2em;
  }
  
  .l-a-sua-doacao-img {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .l-content {
    max-width: 600px;
    margin-top: 30px;
  }
  
  .l-footer-logo-img {
    display: block;
  }
  
  .l-footer-info-area {
    max-width: 500px;
    text-align: right;
  }  

  .l-main-footer-grid-container {
    display: grid;
    grid-template-columns: 180px auto;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}

.l-transfer-box {
  margin-bottom: 8rem;
  padding: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 500px;
  max-width: 80%;
  border: solid 1px $default-blue;
  border-radius: 4px; 
  margin-right: auto;
  margin-left: auto;
  
  p {
    margin-top: 8pt;
    margin-bottom: 8pt;
  }
}
