.l-next-step-button {
  float: right;
  margin-top: 30px;
  display: block;
}

.l-donation-confirmation-button {
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  left: 50%;
  transform: translateX(-55%);
}

.l-login-data-chip {
  float: right;
}