.l-admin-page-content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.l-admin-page-content-container {
  padding-top: 20px;
  padding-left: 40px;
  
  h3 {
    margin-top: 50px;
  }

  table {
    margin-top: 20px;
    max-width: 95%;
  }

  select {
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
    float: right;
  }
}

.l-report-range-container {
  display: inline-block;
  width: 350px;

  li {
    margin-top: 30px;
  }
}

.l-report-query-button {
  display: inline-block;
  margin-left: 100px;
}

.l-search-input {
  width: 450px;
}

.m-admin-update-card-section {
  min-width: 800px;
  min-height: 300px;
  border: solid 1px gray;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 40px;
  padding: 10px;
}

.m-csv-download-button {
  margin-top: 60px;
}

.l-announcements {
  max-width: 400px;
  height: 300px;
  border: solid 1px gray;
  padding: 10px;
}

.l-annoucement-button {
  margin: 25px;
  float: right;
}