@import "../../colors.scss";

.l-preset-amount-buttons-container {
  display: grid;
  grid-template-columns: auto auto auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.l-amount-radio-container {
  margin-top: 10px;
}

.l-present-card-cvv-input {
  margin-left: 30px;
  width: 60px;
  height: 50px;
}

.l-message-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-thanks-other-text-field {
  width: 200px;
  margin-left: 20px;
  margin-top: 10px;
}

.s-thanks-other-inputs {
  margin-top: 10px;
  margin-bottom: 20px;
}

.m-checkbox-container {
  width: 100%;
  height: 40px;
  display: table;
}

.m-checkbox-element {
  display: table-cell;
  vertical-align: middle;
}

.m-valid-date-input {
  margin-left: 10px;
  width: 60px;
}

.l-valid-date-box {
  float: right;
}

.l-cvv-box {
  width: 60px;
}

.m-credit-card-option {
  height: 100px;
}

.l-reset-password-a {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}


.l-is-company-switch-label {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.s-is-company-switch-label-inactive {
  color: gray;
}

.l-is-company-switch-container {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 14px;
  left: 0;
  display: block;
}

.l-user-data-update-list {
  li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.l-donation-guild {
  margin-bottom: 4rem;
}

@media only screen and (min-width: 600px) {
  .l-custom-amount-field {
    width: 180px;
  }

  .l-amount-radio-container {
    display: inline;
    width: 315px;
    border: solid 1px $default-blue;
    border-radius: 4px;
    float: right;
    margin-right: 5px;
  }

  .l-amount-radio-grid {
    display: grid;
    grid-template-columns: auto auto;
  }

  .l-is-company-switch-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .l-preset-amount-buttons-container {
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .m-dontaion-radio {
    display: flex;
    align-content: center;
    position: relative;
    max-width: 160px;
  }

  .m-left-big-field {
    width: 70%;
    display: inline-block;
  }
  
  .m-right-small-field {
    width: 20%;
    float: right;
    display: inline;
  }
}

.l-donation-day-select {
  margin-right: 10px;
  width: 150px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.l-confirmation-text {
  margin-top: 30px;
}

.l-confirmation-title {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline;
  position: relative;
  top: -4px;
}

.l-new-card-forms-container {
  margin-top: 30px;
}

.m-donation-confirmation-chip-set {
  display: inline-block;
  position: relative;
}

.m-user-update-forms-switch {
  margin-left: 10px;
}

.m-list-item-text {
  margin-left: 10px;
}

.l-privacy-policy-check-container {
  margin-top: 2em;
}

.l-save-address-check-container {
  margin-top: 1em;
  margin-bottom: 2em;
}

.m-additional-info-p {
  font-size: 11pt;
  color: $default-gray;
}