@import "../colors.scss";

.l-print-button {
  margin: 20px;
}

.l-amount-column {
width: 80px;
}

.l-year-input {
  width: 100px;
  margin-left: 20px;
  margin-bottom: 30px;
  height: 34px;
}

.l-subscription-change-button-container {
  padding-top: 10px;
  padding-bottom: 3px;
}

.l-change-doantion-button {
  display: block;
}

.l-cancel-subscription-button {
  .mdc-button__label {
    color: $loading-highlight;
  }
}