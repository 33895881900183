@import "../../colors.scss";

.m-login-logout-button {
  float: right;
  margin-top: 30px;
  display: block;
}

.m-main-actions-button {
  display: block;
}

.l-card {
  margin-top: 30px;
  overflow-x: scroll;

  p {
    text-indent: 10pt;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.l-card-title {
  text-align: center;
}

.l-card-content {
  padding: 15px;
}

.mdc-dialog {
  width: 100%;
}

.m-highlight-info {
  border: solid 1px $default-blue;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;

  th {
    border-color: $default-blue;
  }

  td {

    border-color: $default-blue;
  }
}

.l-pending-donations-table-container {
  padding: 5px;
}

@media only screen and (min-width: 650px) {
  .m-main-actions-button {
    display: inline;
  }
}