@import "../colors.scss";

.m-crm-trigger {
  max-width: 800px;

  .m-enable-trigger {
    float: right;

    .m-enable-switch {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
  .m-subject-input {
    margin: 20px;
  }
}

.s-card-to-update {
  box-shadow: 0 0 6px 0 $default-blue;
}